import { React } from "react";
import Header from "../components/header/Header";
import { Divider, Flex, Group, Text, Image, Container, Accordion, Button, Center, Grid, GridCol, Stack, Space } from "@mantine/core";
import { Carousel } from "@mantine/carousel";
import "../styles/Page.css"
import rabbitBreeds from "../data/rabbit-breeds.json"
import { useParams } from 'react-router-dom';

const getBreedbyName = (breeds, name) => {
    return breeds.find(breed => breed.name.toLowerCase() === name.toLowerCase());
};

function BreedProfilePage() {
    // Header
    // will create a page that displays a carousel of pictures of that breed (start with single picture) 
    const { breedName } = useParams();
    
    const adoptionRedirect = () => {
        const url = `https://www.petfinder.com/search/rabbits-for-adoption/us/?breed%5B0%5D=${breedName}`
        window.open(url, '_blank');
    };

    const breederRedirect = () => {

    };

    const breed = getBreedbyName(rabbitBreeds.breeds, breedName);
    return (
        <div>
            <Header />
            <Container
                fluid="true"
                p="0"
            >
                <Group
                    bg="rgba(0, 0, 0, .2)"
                >
                    <Stack
                        p="sm"
                    >
                        <Center
                            bg="#007bff"
                            p="xs"
                            w="50%"
                        >
                            <a href="/breeds"
                                style={{ color: "white", textDecoration: "none", fontWeight: "bold" }}
                            >
                                Back to breeds
                            </a>
                        </Center>
                        <Stack
                            align="center"
                            justify="center"
                        >
                            <Image
                                h={400}
                                w={400}
                                src={breed.img_url}
                                fallbackSrc="/assets/images/stock/rabbitplaceholder.png"
                            />
                        </Stack>
                    </Stack>
                    <Flex
                        direction="column"
                        p="md"
                    >
                        <Text fw={700} size="40px">{breedName}</Text>
                        <Divider color="black"/>
                        <br />
                        <Text fw={700}>Quick Facts</Text>
                        <Group
                            p="md"
                            justify="center"
                        >
                            <Image
                                h={75}
                                w={75}
                                src="/assets/images/stock/rabbit-length-icon.png"
                            >

                            </Image>
                            <Stack>
                                <Text>Length</Text>
                                <Text>{breed.length}</Text>
                            </Stack>
                            <Image
                                h={100}
                                w={100}
                                src="/assets/images/stock/rabbit-weight-icon.png"

                            >
                            </Image>
                            <Stack>
                                <Text>Weight</Text>
                                <Text>{breed.weight}</Text>
                            </Stack>
                            <Group ml="lg">
                                <Image
                                    h={50}
                                    w={50} 
                                    src="/assets/images/stock/certificate-icon.png"></Image>
                                <Stack>
                                    <Text>Certifications</Text>
                                    <Text>{breed.status.join(", ")}</Text>
                                </Stack>
                            </Group>
                        </Group>
                        <Group
                            justify="center"
                        >
                            <Button onClick={adoptionRedirect}>Adopt Today</Button>
                            <Button onClick={breederRedirect}>Find a Breeder</Button>
                        </Group>
                    </Flex>
                </Group>


                <Flex
                    direction="column"
                    p="md"
                >

                    <Text fw={700}>Breed Traits</Text>
                    <Text>Ear Type: {breed.ear_type}</Text>
                    <Text>Coat Type: {breed.coat_type}</Text>
                    <Text>Colors: {breed.colors.join(", ")}</Text>

                    <br/>

                    <Divider/>

                </Flex>
                <Stack
                    p="md"
                >
                    <Text fw={700}>Colors</Text>
                    <Group>

                    </Group>
                </Stack>
                
                <Stack
                    p="md"
                >
                    <Text fw={700}>Characteristics</Text>
                    <Group>
                        {breed.characteristics.includes('Show') && (
                        <Stack
                            align="center"
                        >
                            <Image
                                h={150}
                                w={150}
                                src="/assets/images/stock/rabbit-show.png"
                            />
                            <Text>Show</Text>
                        </Stack>
                        )}
                        {breed.characteristics.includes('Jumping') && (
                        <Stack
                            align="center"
                        >
                            <Image
                                h={150}
                                w={150}
                                src="/assets/images/stock/rabbit-hurdle.png"
                            />
                            <Text>Jumping</Text>
                        </Stack>
                        )}
                    </Group>
                </Stack>
                <Flex
                    bg="rgba(0, 0, 0, .1)"
                    justify="flex-start"
                    p="md"
                    gap="md"
                    direction="column"
                >
                    <Text fw={700}>About the Breed</Text>
                    <Text>{breed.description}</Text>
                </Flex>
                <Accordion>
                    <Accordion.Item value="sources">
                        <Accordion.Control>
                            <Text fw={700}>Sources</Text>
                        </Accordion.Control>
                        <Accordion.Panel>
                            <ul>
                                <li>{breed.img_src}</li>
                                <li></li>
                            </ul>
                        </Accordion.Panel>
                    </Accordion.Item>
                </Accordion>
            </Container>
        </div>
    );
};

export default BreedProfilePage;