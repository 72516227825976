import React from "react";
import Header from "../components/header/Header";
import Footer from "../components/footer/Footer";
import { BackgroundImage, Card, Container, Stack, Text, Image, Grid, Divider } from "@mantine/core";
import "../styles/ArticleCard.css";

const guide = [
    {
        title: "Understanding Rabbits",
        id: "understand",
        img_url: "/assets/images/stock/rabbit-question.png",
    },
    {
        title: "Preparing for Your Rabbit",
        id: "prepare",
        img_url: "/assets/images/stock/rabbit_condo.jpeg",
        img_src: "https://commons.wikimedia.org/wiki/File:Rabbit_condo.jpg"
    },
    {
        title: "Buying Your Rabbit",
        id: "buying",
        img_url: "/assets/images/stock/rabbit-buy.jpeg",
        img_src: "https://commons.wikimedia.org/wiki/File:Baby_rabbits_at_Serramonte_Pet_Shop_1.JPG"
    },
    {
        title: "Basic Rabbit Care",
        id: "basic-care",
        img_url: "/assets/images/stock/rabbit-care-hay.jpg",
        img_src: "https://www.flickr.com/photos/jam_project/31474919137"
    },
    {
        title: "Health and Wellness",
        id: "health",
        img_url: "/assets/images/stock/rabbit-at-vet.jpeg",
        img_src: "https://worldanimalfoundation.org/advocate/rabbit-testing/"
    },
    {
        title: "Behavior and Training",
        id: "behavior",
        img_url: "/assets/images/stock/rabbits-sleeping-behavior.jpg",
        img_src: "https://www.flickr.com/photos/coaly/4909196196"
    },
    {
        title: "Advanced Rabbit Care",
        id: "advanced-care",
        img_url: "/assets/images/stock/rabbit-carrier-adv.jpg",
        img_src: "https://www.flickr.com/photos/tjflex/49024652277"
    },
    {
        title: "The Rabbit Community",
        id: "community",
        img_url: "/assets/images/stock/rabbit-train-jump.jpg",
        img_src: "https://www.flickr.com/photos/tjflex/48996555962/in/photostream/"
    },
    {
        title: "Additional Resources",
        id: "resources",
        img_url: "/assets/images/stock/more-resources.jpg",
        img_src: "https://www.flickr.com/photos/rikkis_refuge/2476932586/"
    }
];

function GettingStartedPage() {

    const scrollToSection = (sectionId) => {
        const section = document.getElementById(sectionId);
        if (section) {
            section.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
    };

    const scrollToTop = () => {
        window.scrollTo({
            
        });
    };

    return (
        <div>
            <Header />
            <Stack justify="center" ml="md">
                <Text fw={700} size="100px">Getting Started</Text>
            </Stack>
            <Grid gutter="lg">
                {
                    guide.map((item, index) => (
                        <Grid.Col span={4}>
                            <Card key={index} bg="rgba(0, 0, 0, .8)" onClick={() => scrollToSection(item.id)} 
                            style={{ cursor: 'pointer', position: 'relative', height: '300px'}} shadow="sm"
                            >
                                <BackgroundImage className="card-image" src={item.img_url} 
                                >
                                </BackgroundImage>
                                <Text p="md" size="25px" fw={700} color="white" style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', textAlign: 'center' }}>{item.title}</Text>
                            </Card>
                        </Grid.Col>
                    ))
                }
            </Grid>

            <Stack justify="center" align="center" mt="lg" p="sm">
                <Stack id="understand" w="50%" align="flex-start">
                    <Text fw={700} size="50px" textDecoration>Understanding Rabbits</Text>
                    <h2>The Benefits of Having a Rabbit as a Pet</h2>
                    <p>
                        Rabbits are fascinating, social animals that love companionship, either from us or their bunny friends. They're most active during dawn and dusk (crepuscular) and communicate through various actions like jumping for joy (binkying), stomping their feet when upset, and gently grinding their teeth when happy. They need a diet rich in hay, fresh veggies, and a sprinkle of pellets to stay healthy.
                    </p>
                    <h2>Popular Breeds</h2>
                    <ul>
                        <li>Netherland Dwarf: Tiny and playful, great for those with limited space.</li>
                        <li>Lionhead: Recognizable by its fluffy mane, friendly and good with families.</li>
                        <li>Holland Lop: Adorable floppy ears and sweet nature, a favorite pet choice.</li>
                        <li>Rex: Velvety fur and a calm demeanor make them perfect for cuddles.</li>
                        <li>Flemish Giant: Gentle giants that need more room but are incredibly docile.</li>
                    </ul>
                    <p>
                        Each breed has its own unique care needs and personality, so choose one that matches your lifestyle.
                    </p>
                    <h2>Why Rabbits Make Great Pets</h2>
                    <ul>
                        <li>Bonding: Rabbits can form close bonds with their owners, showing affection in their gentle ways.</li>
                        <li>Quiet: They’re quiet animals, ideal for those in apartments.</li>
                        <li>Litter Training: With patience, rabbits can be litter trained, making indoor living with them cleaner and easier.</li>
                        <li>Joyful Presence: Their playful antics and curious nature can bring a lot of joy to a home.</li>
                    </ul>
                </Stack>
                <Stack id="prepare" w="50%" align="flex-start">
                    <hr style={{ width: '100%', borderTop: '1px solid #ccc', margin: '16px 0' }} />

                    <Text fw={700} size="50px">Preparing for Your Rabbit</Text>

                    <h2>Choosing the Right Rabbit for Your Lifestyle</h2>
                    <p>When bringing a rabbit into your life, consider your lifestyle and what you can offer your new friend. Each rabbit breed has its own needs, temperament, and energy level. Some, like the playful Netherland Dwarf, might be more suited to energetic families, while the calm and affectionate Rex could be a better match for those seeking a cuddly companion. Age is also a factor; young rabbits are energetic and may require more patience and training, whereas adult rabbits might be calmer and already socialized. Personality matters too—spend some time with potential bunny pals to find a match for your vibe!</p>

                    <h2>Essentials for Rabbit Care</h2>
                    <ul>
                        <li>Housing: A spacious cage or pen is crucial, with plenty of room for your rabbit to move around. Ensure it's safe and escape-proof.</li>
                        <li>Bedding: Line the habitat with soft, absorbent bedding, like paper or straw, to keep your rabbit comfortable and facilitate cleaning.</li>
                        <li>Grooming Supplies: A brush for regular grooming, especially for long-haired breeds, nail clippers, and gentle shampoo suitable for rabbits.</li>
                    </ul>

                    <h2>Creating a Safe and Stimulating Environment</h2>
                    <p>Your rabbit needs an environment that's not just safe but also engaging:</p>

                    <ul>
                        <li>Space to Explore: Provide a secure area where your rabbit can hop, stretch, and play outside their cage.</li>
                        <li>Toys: Offer chew toys, tunnels, and platforms to keep your rabbit entertained and mentally stimulated.</li>
                        <li>Safety: Rabbit-proof any areas your pet will access, covering electrical cords, and removing toxic plants or small objects they might chew on.</li>
                    </ul>

                    <p>Setting up the right environment and choosing a rabbit that fits your lifestyle will ensure a happy start to your life together. Remember, a little preparation goes a long way in welcoming a new furry member to your family!</p>
                </Stack>
                <Stack id="buying" w="50%" align="flex-start">
                    <hr style={{ width: '100%', borderTop: '1px solid #ccc', margin: '16px 0' }} />

                    <Text fw={700} size="50px">Buying Your Rabbit</Text>

                    <h2>Where to Buy a Rabbit: Rescue Centers vs. Breeders</h2>
                    <p>Rescue Centers: Adopting from a rescue center gives a second chance to rabbits in need. It's a heartwarming option that can also offer the advantage of adopting a rabbit that may already be spayed or neutered and socialized.</p>
                    <p>Breeders: If you're looking for a specific breed, reputable breeders are the way to go. Ensure they are ethical, with a clear focus on the health and well-being of their rabbits.</p>
                    <h2>What to Look for in a Healthy Rabbit</h2>
                    <p>When meeting potential bunny companions, look for signs of good health:</p>
                    <ul>
                        <li>Bright, clear eyes without discharge.</li>
                        <li>Clean ears, free from excessive wax or mites.</li>
                        <li>Good appetite and interest in food.</li>
                        <li>Active and curious behavior.</li>
                        <li>Smooth coat without bald patches or dandruff.</li>
                    </ul>
                    <h2>Preparing for the Arrival of Your Rabbit at Home</h2>
                    <p>Before your new friend hops into your life:</p>
                    <ul>
                        <li>Set up their space with a comfortable habitat, including bedding, food, and water dishes.</li>
                        <li>Rabbit-proof your home to create a safe environment for exploration.</li>
                        <li>Stock up on supplies like hay, vegetables, and rabbit-safe toys.</li>
                    </ul>
                    <p>Welcoming a rabbit into your home is an exciting adventure. With the right preparation, you'll create a loving, safe space for your new companion to thrive.</p>
                </Stack>
                <Stack id="basic-care" w="50%" align="flex-start">
                    <hr style={{ width: '100%', borderTop: '1px solid #ccc', margin: '16px 0' }} />

                    <Text fw={700} size="50px">Basic Rabbit Care</Text>

                    <h2>Proper Diet</h2>
                    <ul>
                        <li>Hay: The cornerstone of a rabbit's diet. Timothy, orchard grass, and meadow hay are excellent choices. Hay provides essential fiber for digestive health.</li>
                        <li>Vegetables: Offer a variety of fresh veggies daily. Leafy greens like romaine lettuce, kale, and spinach are favorites. Introduce new vegetables slowly to avoid digestive upset.</li>
                        <li>Pellets: Feed a small amount of high-fiber pellets daily. Choose pellets formulated specifically for rabbits.</li>
                        <li>Water: Always have fresh, clean water available. A water bottle or bowl works fine, but ensure it's cleaned and refilled daily.</li>
                    </ul>
                    <h2>Housing</h2>
                    <ul>
                        <li>Indoor vs. Outdoor: Indoor housing is generally safer, protecting rabbits from predators, harsh weather, and extreme temperatures. Outdoor setups need secure, insulated hutches.</li>
                        <li>Cage Size: The bigger, the better. At a minimum, the cage should be large enough for your rabbit to stretch out and stand up without touching the top. Include a litter box, feeding area, and sleeping spot.</li>
                        <li>Play Areas: Provide a safe, enclosed space for your rabbit to explore and exercise outside their cage. Use baby gates or pens indoors, and ensure outdoor play areas are secure.</li>
                    </ul>
                    <h2>Grooming</h2>
                    <ul>
                        <li>Brushing: Regular brushing keeps the coat healthy and reduces shedding. Long-haired breeds may need daily brushing, while short-haired breeds can be brushed less frequently.</li>
                        <li>Nail Clipping: Trim your rabbit's nails every 4-6 weeks to prevent overgrowth and discomfort.</li>
                        <li>Coat Care: Check regularly for mats, especially in long-haired rabbits. Keep the fur clean and dry to prevent skin issues.</li>
                    </ul>
                    <p>Basic rabbit care is straightforward but essential for your bunny's health and happiness. By providing a proper diet, a comfortable and safe living environment, and regular grooming, you'll ensure your rabbit thrives.</p>
                </Stack>
                <Stack id="health" w="50%" align="flex-start">
                    <hr style={{ width: '100%', borderTop: '1px solid #ccc', margin: '16px 0' }} />

                    <Text fw={700} size="50px">Health and Wellness</Text>

                    <h2>Recognizing Signs of Illness and When to Visit the Vet</h2>
                    <p>Be alert to changes in behavior or appearance, as rabbits often hide their illnesses. Signs that your rabbit needs veterinary attention include:</p>
                    <ul>
                        <li>Lack of appetite or changes in eating habits.</li>
                        <li>Sudden weight loss or gain.</li>
                        <li>Changes in bathroom habits, like diarrhea or constipation.</li>
                        <li>Sneezing, coughing, or difficulty breathing.</li>
                        <li>Lethargy or decreased activity levels.</li>
                        <li>Unusual aggression or changes in behavior.</li>
                    </ul>
                    <p>If you notice any of these signs, it's time to visit the vet. Regular check-ups are also essential for maintaining your rabbit's health.</p>
                    <h2>Common Rabbit Health Issues and Preventive Care</h2>
                    <p>Some common health issues include dental problems, gastrointestinal stasis (GI stasis), and respiratory infections. Preventive measures include:</p>
                    <ul>
                        <li>Providing a high-fiber diet (primarily hay) to support dental and digestive health.</li>
                        <li>Ensuring your rabbit has plenty of exercise to prevent obesity and GI stasis.</li>
                        <li>Keeping the living environment clean to reduce the risk of respiratory issues.</li>
                    </ul>
                    <h2>The Importance of Spaying/Neutering Your Rabbit</h2>
                    <p>Spaying or neutering your rabbit has several benefits:</p>
                    <ul>
                        <li>Reduces the risk of reproductive cancers.</li>
                        <li>Can improve behavior, making rabbits less aggressive and easier to litter train.</li>
                        <li>Prevents unwanted litters, contributing to rabbit welfare and reducing the number of rabbits in shelters.</li>
                    </ul>
                    <p>Spaying/neutering is a crucial step in ensuring a long, healthy, and happy life for your pet rabbit. Consult with your vet about the best time for this procedure for your bunny.</p>
                    <p>By recognizing signs of illness early, providing preventive care, and considering spaying/neutering, you're taking significant steps toward ensuring your rabbit's health and wellness.</p>
                </Stack>
                <Stack id="behavior" w="50%" align="flex-start">
                    <hr style={{ width: '100%', borderTop: '1px solid #ccc', margin: '16px 0' }} />

                    <Text fw={700} size="50px">Behavior and Training</Text>

                    <h2>Understanding Rabbit Behavior and Body Language</h2>
                    <p>Rabbits communicate through a mix of subtle and not-so-subtle body language:</p>
                    <ul>
                        <li>Binkying: A happy jump and twist in the air, showing joy and contentment.</li>
                        <li>Thumping: A sign of fear or alert to danger.</li>
                        <li>Flop: Lying on their side or back, indicating they feel safe and relaxed.</li>
                        <li>Nudging or Nipping: Seeking attention or expressing displeasure.</li>
                    </ul>
                    <p>Learning to interpret these behaviors will help you understand your rabbit's needs and mood.</p>
                    <h2>Litter Training and Other Basic Training Tips</h2>
                    <p>Rabbits are naturally clean animals and can be litter trained with patience:</p>
                    <ul>
                        <li>Place a litter box in the corner of their cage and in any area they spend time in outside the cage.</li>
                        <li>Use rabbit-safe litter and put some hay in the box to encourage use.</li>
                        <li>Reward with treats and gentle praise to reinforce good litter habits.</li>
                    </ul>
                    <p>Other training, like coming when called or hopping onto a scale for weigh-ins, can be achieved with positive reinforcement techniques using treats and patience.</p>
                    <h2>How to Handle and Bond with Your Rabbit</h2>
                    <p>Handling your rabbit correctly is crucial to building trust:</p>
                    <ul>
                        <li>Always approach gently, letting them know you're there.</li>
                        <li>Scoop them up supporting their hindquarters and chest, keeping them close to your body.</li>
                        <li>Spend quality time daily on the floor at their level, letting them come to you.</li>
                    </ul>
                    <p>Bonding with your rabbit involves patience, understanding, and respect for their space and preferences. Offering treats, gentle petting, and engaging in play can deepen your connection.</p>
                    <p>Understanding and responding to your rabbit's behavior, successfully litter training, and bonding through respectful handling and positive reinforcement will lead to a rewarding relationship with your furry friend.</p>
                </Stack>
                <Stack id="advanced-care" w="50%" align="flex-start">
                    <hr style={{ width: '100%', borderTop: '1px solid #ccc', margin: '16px 0' }} />

                    <Text fw={700} size="50px">Advanced Rabbit Care</Text>

                    <h2>Enrichment: Toys, Activities, and Rabbit-Safe Spaces</h2>
                    <p>Enriching your rabbit's environment is key to their happiness and well-being:</p>
                    <ul>
                        <li>Toys: Offer a variety of toys, including chew toys made of safe materials, tunnels for exploring, and balls to push around.</li>
                        <li>Activities: Create obstacle courses or hide treats around their play area to encourage natural foraging behavior.</li>
                        <li>Rabbit-Safe Spaces: Ensure your rabbit has access to a safe, enclosed area where they can explore and exercise without supervision. Remove any hazardous materials and cover electrical cords.</li>
                    </ul>
                    <p>For more details see <a href="/100funthings">100 Fun Things To Do With Your Rabbit</a></p>
                    <h2>Traveling with Your Rabbit</h2>
                    <p>Travel can be stressful for rabbits, so it's important to make it as comfortable as possible:</p>
                    <ul>
                        <li>Use a sturdy, well-ventilated carrier.</li>
                        <li>Include familiar bedding and toys to help them feel secure.</li>
                        <li>Keep trips short and try to maintain a quiet environment.</li>
                        <li>Always have water and hay available.</li>
                    </ul>
                    <h2>Dealing with Behavioral Issues and Creating a Rabbit-Friendly Home</h2>
                    <p>Understanding the root of behavioral issues is crucial:</p>
                    <ul>
                        <li>Chewing: Provide plenty of chew toys and rabbit-proof your home to protect both your rabbit and your belongings.</li>
                        <li>Aggression: Spaying/neutering can reduce hormonal behavior. Understanding your rabbit's body language and giving them space when needed can also help.</li>
                        <li>Litter Training Challenges: Consistency and patience are key. Reinforce positive behaviors with treats and gently guide them back to the litter box when accidents occur.</li>
                    </ul>
                    <p>Creating a rabbit-friendly home means accommodating their needs and behaviors, providing a safe and enriching environment, and being patient as they learn and adapt to living with you. With the right approach, you can address behavioral issues and enjoy a harmonious relationship with your rabbit.</p>
                </Stack>
                <Stack id="community" w="50%" align="flex-start">
                    <hr style={{ width: '100%', borderTop: '1px solid #ccc', margin: '16px 0' }} />

                    <Text fw={700} size="50px">The Rabbit Community</Text>

                    <h2>Joining Rabbit Clubs and Online Communities</h2>
                    <p>Connecting with fellow rabbit enthusiasts offers a wealth of resources, support, and friendship:</p>
                    <ul>
                        <li>Rabbit Clubs: Local and national rabbit clubs often host meetings, workshops, and social events where you can learn and share experiences.</li>
                        <li>Online Communities: Forums, social media groups, and websites dedicated to rabbit care are great places to ask questions, find information, and connect with other rabbit owners globally.</li>
                    </ul>
                    <p>Participating in Shows and Events (For Those Interested)</p>
                    <p>For those interested in the competitive side of rabbit care, participating in shows and events can be a rewarding experience:</p>
                    <ul>
                        <li>Shows: Rabbit shows assess animals based on breed standards and are a great way to learn more about your rabbit's breed and meet breeders and judges.</li>
                        <li>Events: Many communities host rabbit-related events, including fairs, educational seminars, and bunny agility courses, offering fun ways to engage with the rabbit community.</li>
                    </ul>
                    <h2>Volunteering and Advocating for Rabbit Welfare</h2>
                    <p>Giving back to the rabbit community can be incredibly fulfilling:</p>
                    <ul>
                        <li>Volunteering: Rabbit rescues and shelters always need help with caring for rabbits, organizing events, and educating the public.</li>
                        <li>Advocating: Raise awareness about rabbit welfare, responsible pet ownership, and the importance of adoption. Use your voice to advocate for better laws and regulations to protect rabbits.</li>
                    </ul>
                    <p>Becoming involved in the rabbit community not only enriches your own experience as a rabbit owner but also contributes to the well-being and understanding of these wonderful animals. Whether through joining clubs, participating in events, or volunteering, there are many ways to connect with like-minded individuals and make a positive impact.</p>
                </Stack>
                <Stack id="resources" w="50%" align="flex-start">
                    <hr style={{ width: '100%', borderTop: '1px solid #ccc', margin: '16px 0' }} />

                    <Text fw={700} size="50px">Additional Resources</Text>

                    <p>If you need additional information, here are some resources you can check:</p>
                    <ul>

                    </ul>
                </Stack>
            </Stack>
            <Footer />
        </div>
    );
};

export default GettingStartedPage;