import {React, useEffect, useState} from "react";
import {Link, Navigate, useNavigate} from "react-router-dom";
import Header from "../components/header/Header";
import { Text, Grid, Image, Flex, Group, Checkbox, Accordion, Select, Button, Card } from '@mantine/core';
import "../styles/Page.css"
import rabbitBreeds from "../data/rabbit-breeds.json"
import Footer from "../components/footer/Footer";

const filtersList = [
    {
        id: 'characteristics',
        label: 'Characteristics',
        content: ['Show', 'Jumping']
    },
    {
        id: 'ear_type',
        label: 'Ear Type',
        content: ['Lopped', 'Upright']
    },
    {
        id: 'coat_type',
        label: 'Coat Type',
        content: ['Short', 'Rex', 'Long']
    },
    {
        id: 'size',
        label: 'Size',
        content: ['XSmall', 'Small', 'Medium', 'Large']
    }
]

function RabbitBreedPage() {

    const alphabet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'.split('');

    const navigate = useNavigate();

    const firstRowLetters = alphabet.slice(0, 13);
    const secondRowLetters = alphabet.slice(13, 26);

    const [checkedValues, setCheckedValues] = useState({});
    const [jsonData, setJsonData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [dataExistsForLetter, setDataExistsForLetter] = useState([]);
    const [value, setValue] = useState('');

    const initialCheckboxStates = filtersList.reduce((acc, filter) => {
        acc[filter.id] = filter.content.reduce((contentAcc, contentItem) => {
          contentAcc[contentItem] = false;
          return contentAcc;
        }, {});
        return acc;
    }, {});
      
    const [checkboxStates, setCheckboxStates] = useState(initialCheckboxStates);
    
    function initializeDataExistence() {
        const initialData = {};
        alphabet.forEach(letter => {
            initialData[letter] = false;
        });
        return initialData;
    }

    function defaultAlpha() {
        const existence = initializeDataExistence();
        rabbitBreeds.breeds.forEach(breed => {
            const firstLetter = breed.name[0].toUpperCase();
            if (alphabet.includes(firstLetter)) {
                existence[firstLetter] = true;
            }
        });
        setDataExistsForLetter(existence);
    }

    useEffect(() => {
        setJsonData(rabbitBreeds);
        setFilteredData(rabbitBreeds.breeds);
        // defaultCheckboxStates();
        defaultAlpha();
    }, []);

    const handleCheckboxChange = (id, itemContent, isChecked) => {
        setCheckboxStates(prev => ({
            ...prev,
            [id]: {
                ...prev[id],
                [itemContent]: isChecked
            }
        }));
        console.log(checkboxStates);
        setCheckedValues(prev => {
            const updated = {...prev};
            if (isChecked) {
                const current = updated[id] ? [...updated[id]] : [];
                if (!current.includes(itemContent)) {
                    current.push(itemContent)
                }
                updated[id] = current;
            } else {
                // remove itemContent from array
                const current = updated[id] ? [...updated[id]] : [];
                updated[id] = current.filter(item => item !== itemContent);
                if (updated[id].length === 0) {
                    delete updated[id];
                }
            }
            return updated;
        });
        console.log(checkboxStates);
        console.log(checkedValues);
    };

    const handleSubmit = () => {
        const filtered = jsonData.breeds.filter(item => {
            // console.log(item)
            return Object.keys(checkedValues).every(key => {
                // console.log(checkedValues)
                // Skip if no filter is applied for this key
                if (!checkedValues[key] || checkedValues[key].length === 0) return true;
                // For array fields in the item
                if (Array.isArray(item[key])) {
                    return item[key].some(r => checkedValues[key].includes(r));
                } else {
                    // Direct match for non-array fields
                    return checkedValues[key].includes(item[key]);
                }
            });
        });
        
        setFilteredData(filtered);
        // console.log(filteredData);

        const newDataExistsForLetter = {...dataExistsForLetter};
        Object.keys(newDataExistsForLetter).forEach(key => {
            newDataExistsForLetter[key] = false;
        });
        filtered.forEach(breed => {
            const firstLetter = breed.name[0].toUpperCase();
            if (alphabet.includes(firstLetter)) {
                newDataExistsForLetter[firstLetter] = true;
            }
        });
        
        setDataExistsForLetter(newDataExistsForLetter);
    };

    const handleSearchChange = (selectedValue) => {
        setValue(selectedValue);
        navigate(`/breeds/${selectedValue}`);
    };

    const handleLetterClick = (letter) => {
        // handle letter filter logic
        const filteredAlpha = jsonData.breeds.filter(item => {
            return item.name.startsWith(letter);
        })

        setFilteredData(filteredAlpha);
        // setDataExistsForLetter(newDataExistsForLetter);
    };

    const resetFilters = (e) => {
        e.preventDefault();
        setCheckedValues({});
        setCheckboxStates(initialCheckboxStates);
        setFilteredData(jsonData.breeds);
        defaultAlpha();
        // defaultCheckboxStates();
    }



    const filters = filtersList.map((item) => (
        <Accordion.Item value={item.label} key={item.label}>
            <Accordion.Control>
                <Text>{item.label}</Text>
            </Accordion.Control>
            <Accordion.Panel>
                <Grid>
                    {item.content.map((contentItem, index) => (
                        <Grid.Col span={6} key={index}>
                            <Checkbox 
                            label={contentItem}
                            checked={checkboxStates[item.id][contentItem]}
                            onChange={(e) => {
                                console.log(`${contentItem} is now ${e.target.checked ? 'checked' : 'unchecked'}`);
                                // setCheckboxStates(e.target.checked);
                                handleCheckboxChange(item.id, contentItem, e.target.checked);
                            }}
                            />
                        </Grid.Col>
                    ))}
                </Grid>
            </Accordion.Panel>
        </Accordion.Item>
    ));

    return (
        <div>
            <Header/>
            <Grid>
                <Grid.Col span={4} p="md">
                    <p>Find by Breed Name</p>
                    {/* TODO: Implement searching filter */}
                    <Select data={rabbitBreeds.breeds.map(breed => ({value: breed.name, label: breed.name}))} 
                        placeholder="Select A Breed" searchable value={value} onChange={handleSearchChange}/>
                    <Accordion multiple>
                        {filters}
                    </Accordion>
                    <Button onClick={handleSubmit} mr="sm">Submit</Button>
                    <a href="#" onClick={resetFilters}>Clear All</a>
                </Grid.Col>
                <Grid.Col span={8}>
                    <Text size="xl" weight={500}>Rabbit Breeds</Text>
                    <Text size="sm">This is a list of all unofficial and officially recognized rabbit breeds.</Text>
                    <Text size="sm">Filter by letter</Text>
                    <Grid>
                        <Flex>
                        {firstRowLetters.map((letter) => (
                            <Grid.Col span={1} key={letter}>
                                <Button 
                                    size="xs" 
                                    color={dataExistsForLetter[letter] ? 'blue' : 'gray'} 
                                    onClick={() => handleLetterClick(letter)}
                                    disabled={!dataExistsForLetter[letter]}
                                    >
                                    {letter}
                                </Button>
                            </Grid.Col>    
                        ))}
                        </Flex>
                    </Grid>
                    <Grid>
                        <Flex>
                        {secondRowLetters.map((letter) => (
                            <Grid.Col span={1} key={letter}>
                                <Button 
                                    size="xs" 
                                    color={dataExistsForLetter[letter] ? 'blue' : 'gray'} 
                                    onClick={() => handleLetterClick(letter)}
                                    disabled={!dataExistsForLetter[letter]}
                                    >
                                    {letter}
                                </Button>
                            </Grid.Col>
                        ))}
                        </Flex>
                    </Grid>
                    <Grid>
                        {filteredData.map((breed) => (
                            <Grid.Col span={6} key={breed.name}>
                            <Card shadow="sm" component={Link} to={`/breeds/${breed.name}`}>
                                <Card.Section>
                                    <Image
                                        src={breed.img_url} 
                                        alt={breed.name} 
                                        fallbackSrc="/assets/images/stock/rabbitplaceholder.png"/>
                                </Card.Section>
                                <Text weight={500}>{breed.name}</Text>
                                <Text size="sm">{breed.description}</Text>
                            </Card>
                            </Grid.Col>
                        ))}
                    </Grid>
                </Grid.Col>
            </Grid>
            <Footer/>
        </div>
    );
}

export default RabbitBreedPage;