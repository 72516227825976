import React from "react";
import Header from "../components/header/Header";
import Footer from "../components/footer/Footer";
import { Text, Stack } from "@mantine/core";

function OtherResourcesPage() {
    return (
        <div>
            <Header />
            <Stack justify="center" ml="md">
                <Text fw={700} size="100px">Other Resources</Text>
            </Stack>
            <Stack justify="center" align="center" mt="lg" p="sm">
                <Stack id="understand" w="90%" align="flex-start">
                    <Text fw={700} size="50px" textDecoration>Websites About Rabbits</Text>
                    <Text>Here are some websites we recommend to learn more about taking care of rabbits</Text>
                    <ul>
                        <li><a href="https://rabbit.org">House Rabbit Society</a></li>
                        <li><a href="https://myhouserabbit.com">My House Rabbit</a></li>
                        <li><a href="https://rabbitwelfare.co.uk">Rabbit Welfare Association & Fund (RWAF)</a></li>
                        <li><a href="https://binkybunny.com">BinkyBunny</a></li>
                        <li><a href="https://rabbitpedia.com">RabbitPedia</a></li>
                        <li><a href="https://101rabbits.com">101 Rabbits</a></li>
                    </ul>
                </Stack>
                <Stack id="understand" w="90%" align="flex-start">
                    <Text fw={700} size="50px" textDecoration>Rabbit Food & Care</Text>
                    <Text>Here are some websites you can order Rabbit food and care supplies from</Text>
                    <ul>
                        <li><a href="https://chewy.com">Chewy</a></li>
                        <li><a href="https://smallpetselect.com">Small Pet Select</a></li>
                        <li><a href="https://store.binkybunny.com">BinkyBunny</a></li>
                        <li><a href="https://oxbowanimalhealth.com">Oxbow Animal Health</a></li>
                        <li><a href="https://amazon.com">Amazon</a></li>
                        <li><a href="https://petsathome.com">Pets at Home</a></li>
                        <li><a href="https://rabbitholehay.com">Rabbit Hole Hay</a></li>
                        <li><a href="https://etsy.com">Etsy</a></li>
                    </ul>
                </Stack>
            </Stack>
            <Footer />
        </div>
    );
};

export default OtherResourcesPage;

