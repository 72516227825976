import React, {useState} from "react";
import {Paper, Group, TextInput, PasswordInput, Button, Text, Anchor} from "@mantine/core";
import { signInUserEmailPass } from "./auth_signin_password";
import { useNavigate } from "react-router-dom";

function SignInForm() {
    let navigate = useNavigate();


    const [isLoading, setIsLoading] = useState(false);
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    
    const handleSubmit = (event) => {
        event.preventDefault();

        setIsLoading(true);
        
        // handle sign in
        signInUserEmailPass(email, password)
            .then(() => {
                navigate("/")
            })
            .catch((error) => {
                console.log("error in signing in")
            })
            .finally(() => {
                setIsLoading(false);
            });
    }

    return (
        <Paper padding='lg' shadow="sm">
            <form onSubmit={handleSubmit}>
                <TextInput
                    label="email"
                    placeholder="Email"
                    required
                    onChange={(e) => setEmail(e.target.value)}
                />
                {/* <TextInput
                    label="username"
                    placeholder="Username"
                    required
                    onChange={(e) => setUsername(e.target.value)}
                /> */}
                <PasswordInput
                    label="password"
                    placeholder="Password"
                    required
                    onChange={(e) => setPassword(e.target.value)}
                    mt="md"
                />
                <Button type='submit' disabled={isLoading} fullWidth mt="xl">Sign In</Button>
            </form>
            
            <Group>

            </Group>
            
            <Text>
                Already have an account? <Anchor href="/sign-in">Sign In</Anchor>
            </Text>
        </Paper>
    );
}

export default SignInForm;