import React, { useState } from "react";
import { ActionIcon, Modal, Button } from '@mantine/core'
import { MessageCircle } from 'tabler-icons-react';

function ChatPopup() {
    const [opened, setOpened] = useState(false);

    return (
        <>
        <Modal
            opened={opened}
            onClose={() => setOpened(false)}
            title="Chat with us!"
        >
            {/* Chat interface or messaging component goes here */}
            <a href="https://chat.openai.com/g/g-nJiiwNx8N-rabbit-care">Click here to chat with Izzie the AI</a>
        </Modal>
        <ActionIcon
            onClick={() => setOpened(true)}
            size='lg'
            style={{
                position: 'fixed',
                bottom: 20,
                right: 20,
                zIndex: 1000,
            }}
        >
            <MessageCircle size={24} />
        </ActionIcon>
        </>
    );
}

export default ChatPopup;