import { getAuth, signInWithEmailAndPassword } from "firebase/auth";


const auth = getAuth();

export function signInUserEmailPass(email, password) {
    return signInWithEmailAndPassword(auth, email, password)
        .then((userCredential) => {
            // Signed in
            const user = userCredential.user;
            // ...
        })
        .catch((error) => {
            console.log(error)
        });
}