import React from "react";
import Header from "../components/header/Header";
import Footer from "../components/footer/Footer";
import { Text, Stack } from "@mantine/core";
import { IconTemperatureSnow } from "@tabler/icons-react";

function FunThingsPage() {
    const funThings = ["Create a bunny-safe obstacle course.",
        "Teach your rabbit to come when called.",
        "Have a mini photoshoot.",
        "Make DIY rabbit toys from cardboard.",
        "Set up a rabbit-friendly digging box.",
        "Teach your rabbit to jump through a hoop.",
        "Enjoy a lazy day watching TV together.",
        "Introduce new, rabbit-safe foods as treats.",
        "Craft a bunny castle out of boxes.",
        "Play hide and seek with treats.",
        "Learn and practice clicker training.",
        "Have a picnic outside in a safe, enclosed area.",
        "Groom your rabbit with a soft brush.",
        "Read a book out loud for your bunny.",
        "Try rabbit agility training.",
        "Set up a bunny-proofed room for exploration.",
        "Create a foraging toy with hidden treats.",
        "Make a willow twig ball for your rabbit to chew on.",
        "Share a peaceful nap together.",
        "Teach your rabbit to spin around.",
        "Enjoy some quiet cuddle time.",
        "Plant a small garden of rabbit-safe herbs.",
        "Build a maze out of cardboard.",
        "Introduce your rabbit to gentle new sounds.",
        "Have a gentle dance party with your bunny.",
        "Offer a variety of safe chew toys.",
        "Make frozen treats for hot days.",
        "Set up a rabbit-safe balcony garden.",
        "Teach your rabbit to stand on hind legs (for a short time).",
        "Create a sensory box with different safe materials.",
        "Have a supervised outdoor adventure in a safe area.",
        "Practice gentle handling to build trust.",
        "Try a gentle rabbit yoga session.",
        "Watch the sunrise or sunset together.",
        "Experiment with rabbit-safe scents like lavender.",
        "Celebrate your bunny's birthday or adoption day.",
        "Make a puzzle feeder with hidden treats.",
        "Craft a rabbit-safe wreath from hay.",
        "Have a spa day with nail trimming and grooming.",
        "Play fetch with a small, light ball.",
        "Set up a comfortable watching spot by the window.",
        "Do a DIY project to improve or expand the rabbit's habitat.",
        "Organize a playdate with another rabbit (carefully introduced).",
        "Create a bunny scrapbook with photos and milestones.",
        "Teach your rabbit to use a litter box in a new area.",
        "Sing to your rabbit.",
        "Try out different types of safe bedding.",
        "Give your rabbit gentle massages.",
        "Make an enrichment calendar with a new activity each day.",
        "Introduce a new, safe toy and see how they interact with it.",
        "Craft a DIY leash for supervised outdoor time.",
        "Experiment with rabbit-appropriate music.",
        "Play gentle games of tag in a safe, enclosed space.",
        "Create a photo album or digital slideshow of bunny adventures.",
        "Have a \"bunny and me\" tea time with safe herbs.",
        "Make a treat-dispensing toy from a toilet paper roll.",
        "Set up a quiet, cozy corner for relaxation.",
        "Experiment with different types of hay.",
        "Teach your rabbit gentle tricks with positive reinforcement.",
        "Share a moment of silence together, just enjoying each other's company.",
        "Try hand feeding to build a closer bond.",
        "Build a platform for your rabbit to jump onto.",
        "Create a bunny art piece with non-toxic paints.",
        "Introduce a soft, washable rug or blanket for comfort.",
        "Watch wildlife together safely from a window.",
        "Explore rabbit-safe apps or games.",
        "Teach your rabbit to navigate a simple path.",
        "Organize your bunny's supplies into a dedicated area.",
        "Try different grooming tools to see what your bunny prefers.",
        "Create a \"bunny-proof\" zone for safe exploration.",
        "Prepare a special meal with safe, favorite veggies.",
        "Set up an interactive feeder to stimulate their mind.",
        "Start a bunny journal documenting your daily activities.",
        "Play soft, soothing music to see how your bunny reacts.",
        "Introduce ice cubes with frozen fruit inside as a treat.",
        "Craft a safe, bunny-sized hammock or resting place.",
        "Make a \"bunny bouquet\" of safe, edible flowers and herbs.",
        "Plan a themed photo day, with rabbit-safe props.",
        "Create a memory box with small items like their first toy.",
        "Have a gentle brushing session to help with shedding.",
        "Experiment with bunny-safe vegetable ice pops.",
        "Curate a playlist of relaxing sounds for your rabbit.",
        "Make a bunny first aid kit together (for you to use).",
        "Organize a quiet storytime with children's books.",
        "Document your rabbit's behavior to learn more about them.",
        "Practice name recognition with treats.",
        "Set up a \"bunny cam\" to watch your rabbit when you're away.",
        "Teach your rabbit to push a small ball to get treats.",
        "Create a cozy hideaway with blankets and pillows.",
        "Plan a safe, indoor picnic with rabbit-friendly foods.",
        "Make a hanging herb garden for fresh treats.",
        "Experiment with different textures of toys and materials.",
        "Create a custom rabbit-themed calendar with photos.",
        "Develop a routine to help your rabbit feel secure.",
        "Make a simple DIY treat trail with hidden surprises.",
        "Play a game of gentle \"keep away\" with a favorite toy.",
        "Craft a safe, edible wreath or garland for your rabbit to enjoy.",
        "Start a small, indoor herb garden for fresh rabbit snacks.",
        "Take time to learn about rabbit behavior and body language.",
        "Just sit quietly together, enjoying each other's presence."];
    return (
        <div>
            <Header/>
            <Stack justify="center" ml="md">
                <Text fw={700} size="100px">100 Fun Things to Do</Text>
                <Stack w="80%" align="flex-start">
                    <Text>Here are 100 activities recommended by our AI that you can try doing with your bunny!</Text>
                    <ol>
                        {funThings.map((item, index) => (
                            <li key={index}>{item}</li>
                        ))}
                    </ol>
                </Stack>
            </Stack>
            <Footer/>
        </div>
    );
};

export default FunThingsPage;

