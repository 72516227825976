import React from "react";
import { Link } from "react-router-dom";
import { Container } from "@mantine/core";

import "../../styles/Header.css";

function AuthHeader () {
    return (
        <header className="header">
            <Container fluid>
            <section className="header-title">
                    <h1 href="/" className="header-logo">
                        <Link to="/" className="header-logo-title">Rabbit Care</Link>
                    </h1>
                </section>
            </Container>
        </header>
    );
}

export default AuthHeader; 