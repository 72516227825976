import React from "react";
import Header from "../components/header/Header";
import Footer from "../components/footer/Footer";

import {Link} from "react-router-dom";

import { Grid, Card, Text } from "@mantine/core";

function ArticlePage() {
    const articles = [
        {
            title: "Getting Started",
            link: "/gettingstarted"
        },
        {
            title: "100 Fun Things To DO",
            link: "/100funthings"
        },
        {
            title: "Common Myths About Rabbits",
            link: "/common-myths"
        },
        {
            title: "Other Resources",
            link: "/other-resources"
        }
    ]
    return (
        <div>
            <Header/>
            <Grid gutter="lg">
                {
                    articles.map((item, index) => (
                        <Grid.Col span={6}>
                            <Card key={index} bg="rgba(0, 0, 0, .8)" component={Link} to={item.link}
                            style={{ cursor: 'pointer', position: 'relative', height: '300px'}} shadow="sm"
                            >
                                <Text p="md" size="25px" fw={700} color="white" style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', textAlign: 'center' }}>{item.title}</Text>
                            </Card>
                        </Grid.Col>
                    ))
                }
            </Grid>
            <Footer/>
        </div>
    );
}

export default ArticlePage;