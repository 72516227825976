import { createUserWithEmailAndPassword, updateProfile } from "firebase/auth";
import {auth} from "../../config/firebase";



export function signUpEmailUserPasswordInitial (email, password, username) {

    if (!isUsernameValid(username)) {
        return Promise.reject(new Error('Invalid username'));
    }

    return createUserWithEmailAndPassword(auth, email, password)
        .then((userCredential) => {
            // Signed up
            const user = userCredential.user;
            
            return updateProfile(user, {
                displayName: username
            });
            
            // set up email verification in the future
            // ...
        })
        .then(() => {
          console.log("Username updated.");  
        })
        .catch((error) => {
            console.log(error);
            throw error;
        });
}

function isUsernameValid(username) {
    return /^[a-zA-Z0-9_]{3,15}$/.test(username);
}

function isUsernameUnique(username, database) {
    return database.collection('users').doc(username).get()
    .then((doc) => !doc.exists);
}

