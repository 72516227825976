import './App.css';
import { React, useEffect } from 'react'
import { BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import { MantineProvider } from '@mantine/core'

import '@mantine/carousel/styles.css';
import "@mantine/core/styles.css";
import "./styles/ArticleCard.css";

import HomePage from './pages/HomePage';
import SocialMediaPage from './pages/SocialMediaPage';
import ArticlePage from './pages/ArticlePage';
import ChatPopup from "./components/chat/ChatPopup";
import SignInPage from './pages/SignInPage';
import SignUpPage from './pages/SignUpPage';
import AccountPage from './pages/AccountPage';
import RabbitBreedPage from './pages/RabbitBreedPage';
import BreedProfilePage from './pages/BreedProfilePage';
import GettingStartedPage from './pages/GettingStartedPage';
import FunThingsPage from './pages/FunThingsPage';
import UnderConstructionPage from './pages/UnderConstructionPage';
import CommonMythsPage from './pages/CommonMythsPage';
import OtherResourcesPage from './pages/OtherResourcesPage';


function App() {

  useEffect(() => {
    const link = document.createElement('link');
    link.rel = 'icon';
    link.href = '/assets/images/logos/RabbitCare.webp'

    document.head.appendChild(link);

    return () => {
      document.head.removeChild(link);
    };
  }, []);

  return (
    <MantineProvider>
      <Router>
        <Routes>
          <Route path="/" element={<HomePage/>}/>
          <Route path="/articles" element={<ArticlePage />}/>
          <Route path="/socialmedia" element={<SocialMediaPage/>}/>
          <Route path="/sign-in" element={<SignInPage/>}/>
          <Route path="/sign-up" element={<SignUpPage/>}/>
          <Route path="/account" element={<AccountPage/>}/>
          <Route path="/breeds" element={<RabbitBreedPage/>}/>
          <Route path="/breeds/:breedName" element={<BreedProfilePage/>}/>
          <Route path="/gettingstarted" element={<GettingStartedPage/>}/>
          <Route path="/100funthings" element={<FunThingsPage/>}/>
          <Route path="/common-myths" element={<CommonMythsPage/>}/>
          <Route path="/other-resources" element={<OtherResourcesPage/>}/>
          <Route path="/underconstruction" element={<UnderConstructionPage/>}/>
        </Routes>
      </Router>
      <ChatPopup/>
    </MantineProvider>
  );
}

export default App;
