import React, { useEffect, useRef } from "react";
import Autoplay from 'embla-carousel-autoplay';
import { Link } from "react-router-dom";
import { Carousel } from "@mantine/carousel";
import { Image, Container, Card, Text } from "@mantine/core";

import '../../styles/HomePageCarousel.css';
import { BorderRadius } from "tabler-icons-react";


const data = [
    {
        title: "Welcome to Rabbit Care",
        img_url: "/assets/images/stock/welcome-image.png",
        link: "#about"
    },
    {
        title: "Find A Breed You Like",
        img_url: "/assets/images/stock/rabbit-breed-explorer.png",
        link: "/breeds"
    },
    {
        title: "First Time Owner? Getting Started",
        img_url: "/assets/images/stock/getting-started-bunny.png",
        link: "/gettingstarted"
    },
    {
        title: "Fun Things To Do With Your Rabbit",
        img_url: "/assets/images/stock/rabbit-fun.png",
        link: "/100funthings"
    },
    {
        title: "Chat With An AI Rabbit Expert",
        img_url: "/assets/images/logos/RabbitCareLogoAITry.png",
        link: "https://chat.openai.com/g/g-nJiiwNx8N-rabbit-care"
    }
]

const PlaceholderImage = ({ text, width, height }) => {
    const canvasRef = useRef(null);

    useEffect(() => {
        const canvas = canvasRef.current;
        const ctx = canvas.getContext('2d');

        ctx.fillStyle = '#ffffff';
        ctx.fillRect(0, 0, width, height);

        ctx.fillStyle = '#000000';
        ctx.font = '16px Arial';
        ctx.fillText(text, 10, 30);
    }, [text, width, height]);

    return <canvas ref={canvasRef} width={width} height={height} />;
};

function HomePageCarousel() {

    const autoplay = useRef(Autoplay({ delay: 3000 }));

    const scrollToSection = (sectionId) => {
        const section = document.getElementById(sectionId);
        if (section) {
            section.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
    };

    const slides = data.map((url) => {
        if (url.title === "Welcome to Rabbit Care") {
            return (
                <Carousel.Slide key={url.title} onClick={() => scrollToSection('about')} style={{cursor: 'pointer'}} className="carousel-slide">
                    <Card>
                        <Card.Section>
                            <Image src={url.img_url} className="carousel-image"/>
                        </Card.Section>
                    </Card>
                </Carousel.Slide>
            );
        } else {
            return (
                <Carousel.Slide key={url.title} className="carousel-slide">
                    <Card component={Link} to={url.link}>
                        <Card.Section>
                            <Image src={url.img_url} className="carousel-image"/>
                            {/* <PlaceholderImage text={url.title} width={200} height={200}/> */}
                        </Card.Section>
                    </Card>
                </Carousel.Slide>
            );
        };

    });

    return (
        <Container>
            <Carousel
                withIndicators
                slideSize="100%"
                draggable="false"
                loop
                height="100%"
                align="center"
                slideGap="md"
                plugins={[autoplay.current]}
                onMouseEnter={autoplay.current.stop}
                onMouseLeave={autoplay.current.reset}
                styles={{
                    indicator: {
                        width: 50,
                        height: 10,
                        background: "white",
                        transition: "width 250ms ease",
                        "&[dataActive]": { width: 40 }
                    },
                    control: {
                        background: "white",
                        width: 50,
                        height: 50,
                        borderRadius: "50%",
                        margin: '0 -65px'
                    }
                }}
            >
                {slides}
            </Carousel>
        </Container>
    );

};

export default HomePageCarousel;