import React from "react";
import { Container, Text, Group, Anchor} from "@mantine/core";
import "../../styles/Footer.css"


function Footer () {
    return (
        <footer className="footer">
            <Container fluid className="footer-inner">
                <Group>
                    <Text>© 2024 Rabbit Care by Izzie</Text>
                    <Anchor href="/privacy-policy" c="black" className="footer-link">
                        Privacy Policy
                    </Anchor>
                    <Anchor href="/about" c="black" className="footer-link">
                        About
                    </Anchor>
                    <Anchor href="/contact" c="black" className="footer-link">
                        Contact
                    </Anchor>
                </Group>
            </Container>
        </footer>
    );
}

export default Footer;