import React from "react";
import Header from "../components/header/Header";
import "../styles/Page.css"

function AccountPage() {
    return (
        <div className="page-container">
            <Header/>
        </div>
    );
}

export default AccountPage;