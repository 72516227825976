import React from "react";
import { Link } from "react-router-dom";
import { Menu, Text } from "@mantine/core";

function ArticleDropDown() {
    const menuItems = [
        {
            title: "Getting Started",
            link: "/gettingstarted"
        },
        {
            title: "Fun Things To Do",
            link: "/100funthings"
        },
        {
            title: "Common Myths",
            link: "/common-myths"
        },
        {
            title: "Other Resources",
            link: "/other-resources"
        }
    ];
    return (<Menu
        trigger="hover"
        delay={100}
    >
        <Menu.Target>
            <Text>Articles</Text>
        </Menu.Target>
        <Menu.Dropdown>
            {menuItems.map((item, index) => (
                <Menu.Item key={index} component={Link} to={item.link}>
                    {item.title}
                </Menu.Item>
            ))}

        </Menu.Dropdown>

    </Menu>);
    
};

export default ArticleDropDown;