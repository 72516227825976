import React from "react";
import Header from "../components/header/Header";
import Footer from "../components/footer/Footer";
import "../styles/Page.css";

function SocialMediaPage() {
    return (
        <div className="page-container">
            <div className="header-container">
                <Header/>
            </div>
            <div className="page-content">
                <h2>Connect with Us</h2>
                <p>Follow us on social media for the latest updates and tips on rabbit care.</p>
            </div>
            <Footer/>
        </div>
    );
}

export default SocialMediaPage;