import React from "react";
import Header from "../components/header/Header";
import Footer from "../components/footer/Footer";
import { Flex, Stack, Text, Image} from "@mantine/core";


function UnderConstructionPage() {
    return (
        <div>
            
            <Stack gap={0}>
                <Header/>
                <Flex bg="rgba(0, 0, 0, .1)" p="lg">
                    <Text size="20px"><a href="/">Return to Home</a></Text>
                </Flex>
                <Flex justify="center" align="center" style={{ height: '81.5vh', width: '100vw' }} bg="rgba(0, 0, 0, .1)">
                    <Image h="50%" w="50%" src="/assets/images/logos/constructionbunny.png"/>
                </Flex>
                <Footer/>
            </Stack>
        </div>
    );
};

export default UnderConstructionPage;