import React, { useState } from "react";
import { TextInput, Button, Group } from '@mantine/core'

function SearchBar() {
    const [searchTerm, setSearchTerm] = useState('');

    const handleSearch = () => {
        console.log('Searching for:', searchTerm);
    };

    return (
        <Group>
            <TextInput
                placeholder="Search"
                value={searchTerm}
                onChange={(event) => setSearchTerm(event.currentTarget.value)}
                onKeyPress={(event) => event.key === 'Enter' && handleSearch()}
            />
            <Button onClick={handleSearch}>Search</Button>
        </Group>
    )
}

export default SearchBar;