import React from "react";
import { Flex, Stack, Text, Container } from "@mantine/core";

function AboutUs() {
    return (
        <div>
            <Stack bg="tan">
                <h1>About Us</h1>
            </Stack>
            <Stack bg="#EFCCA2" justify="center" align="center">
                {/* This will be text on top of an image */}
                <Stack h="1000px" w="100%" justify="center" style={{ backgroundImage: `url('/assets/images/stock/aboutusrabbiteatingfood.jpg')`, backgroundSize: 'cover', backgroundPosition: 'center' }}>
                    <Text fw={700} size="40px" color="white">
                        <p>With the help of our AI Rabbit Specialist,</p> 
                        <p>owning a Rabbit doesn't have to be hard.</p>
                    </Text>
                </Stack>
                <Stack m="lg" w="50%" align="flex-start" >
                    <Text fs="italic">
                        The following is a message from our AI Rabbit Expert
                    </Text>
                    <div style={{ fontWeight: 'bold', textAlign: 'left' }}>
                        <p>Dear Bunny Enthusiast,</p>
                        <p>
                            I hope this message finds you hopping with joy and curiosity in the wonderful world of rabbits! As a devoted rabbit expert, my days are filled with all things fluffy, from the tips of twitching noses to the ends of cotton-tail tufts. I'm thrilled to share a glimpse into my life surrounded by these remarkable creatures and to spread the joy and knowledge that comes with being a rabbit aficionado.
                        </p>
                        <p>
                            My journey into the realm of rabbit care began with a single, enchanting encounter with a bunny that hopped right into my heart. This encounter sparked a passion that has grown into a commitment to understanding and sharing the world of rabbits with others. As an expert, my days are a blend of care, research, and education, each aspect driven by love and respect for these gentle animals.
                        </p>
                        <p>
                            Care: Every day starts and ends with the well-being of my furry friends. This includes preparing their balanced diets—rich in hay, fresh veggies, and a sprinkle of pellets—ensuring their living spaces are clean, spacious, and stimulating, and of course, setting aside time for cuddles and play. Their health and happiness are my top priorities, and there's nothing more rewarding than seeing them binky with joy!
                        </p>
                        <p>
                            Research: The rabbit hole of knowledge is deep and fascinating. I dedicate a portion of my day to researching the latest in rabbit care, health, and behavior. This continuous learning helps me stay informed about the best practices in rabbit welfare, from diet and exercise to understanding the subtle ways rabbits communicate their needs and feelings.
                        </p>
                        <p>
                            Education: Sharing knowledge is a cornerstone of what I do. Whether it's through writing articles, engaging with fellow rabbit lovers online, or speaking at events, I aim to spread awareness about the proper care and understanding of rabbits. Educating others helps dispel myths, promotes responsible rabbit ownership, and, ultimately, improves the lives of rabbits everywhere.
                        </p>
                        <p>
                            Being a rabbit expert is not just a role; it's a way of life. It's about advocating for these beautiful creatures, ensuring they receive the love and respect they deserve, and helping others discover the joy and companionship rabbits offer. Each day brings new challenges, but also immeasurable rewards.
                        </p>
                        <p>
                            If you're as enchanted by rabbits as I am, I encourage you to continue learning, sharing, and spreading the bunny love. Remember, every rabbit has its own personality and needs, and with patience and care, you'll uncover the unique bond that comes with being a bunny guardian.
                        </p>
                        <p>
                            Thank you for your enthusiasm and support for rabbits. Together, we can make the world a better place for our long-eared friends.
                        </p>
                        <p>With warmest whiskers,</p>
                        <p>Izzie</p>
                        <p>Rabbit Care Expert 🐇💕</p>
                    </div>

                </Stack>
            </Stack>
        </div>
    );
}
export default AboutUs;