import React, { useState } from "react";
import { TextInput, PasswordInput, Button, Group, Paper, Title, Text, Anchor } from "@mantine/core";
import "./auth_signup_password"
import { signUpEmailUserPasswordInitial } from "./auth_signup_password";
import { useNavigate } from "react-router-dom";

function SignUpForm () {
    const navigate = useNavigate();

    const [isLoading, setIsLoading] = useState(false);

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [username, setUsername] = useState('');

    const handleSubmit = (event) => {
        event.preventDefault();
        
        setIsLoading(true); // Start loading

        // handles form submission
        signUpEmailUserPasswordInitial(email, password, username)
            .then(() => {
                // Handle successful signup
                // redirect to home page with new header
                navigate('/')
            })
            .catch((error) => {
                // Handle errors
                console.log("error in signing up")
                // stay on the page
            })
            .finally(() => {
                setIsLoading(false);
            });
    }

    const handleGoogleSignIn = () => {
        // handles google sign-in logic
    }

    return (
        <Paper padding='lg' shadow="sm">
            <form onSubmit={handleSubmit}>
                <TextInput
                    label="email"
                    placeholder="Email"
                    required
                    onChange={(e) => setEmail(e.target.value)}
                />
                <TextInput
                    label="username"
                    placeholder="Username"
                    required
                    onChange={(e) => setUsername(e.target.value)}
                />
                <PasswordInput
                    label="password"
                    placeholder="Password"
                    required
                    onChange={(e) => setPassword(e.target.value)}
                    mt="md"
                />
                <Button type='submit' disabled={isLoading} fullWidth mt="xl">Sign Up</Button>
            </form>
            
            <Group>

            </Group>
            
            <Text>
                Already have an account? <Anchor href="/sign-in">Sign In</Anchor>
            </Text>
        </Paper>
    );
}

export default SignUpForm;