import { initializeApp} from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import {getFirestore} from "firebase/firestore";
import {getAuth} from "firebase/auth";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyChWztrMfzfFu3H7gULAkJRunxGSZ51ypU",
    authDomain: "rabbit-care-ca437.firebaseapp.com",
    projectId: "rabbit-care-ca437",
    storageBucket: "rabbit-care-ca437.appspot.com",
    messagingSenderId: "934556047411",
    appId: "1:934556047411:web:c7135fb099f676d98bab46",
    measurementId: "G-WD0ZGMFNW8"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export const auth = getAuth();
export const firestore = getFirestore();

const analytics = getAnalytics(app);

