import React from "react";
import Header from "../components/header/Header";
import Footer from "../components/footer/Footer";
import { Text, Stack } from "@mantine/core";

function CommonMythsPage() {

    const commonMyths = [
        {
            "myth": "Rabbits are low-maintenance, easy pets.",
            "truth": "Rabbits require as much care and attention as dogs and cats. They need a balanced diet, regular vet check-ups, daily exercise, and mental stimulation."
        },
        {
            "myth": "Rabbits are great starter pets for children.",
            "truth": "Rabbits can be fragile and easily frightened, requiring gentle handling. Their care needs are complex, making them better suited for adults or families with older children who understand how to interact with them responsibly."
        },
        {
            "myth": "Rabbits should live in a small cage.",
            "truth": "Rabbits need space to run, jump, and explore. A small cage is not suitable for a rabbit's home. Instead, they should have a large pen or a bunny-proofed room where they can roam freely for several hours each day."
        },
        {
            "myth": "Rabbits only eat carrots and lettuce.",
            "truth": "A rabbit's diet should primarily consist of hay, along with a balanced amount of fresh vegetables and a small portion of pellets. Carrots and lettuce should only be given in moderation; carrots are high in sugar, and iceberg lettuce has little nutrition and is sometimes dangerous for rabbits."
        },
        {
            "myth": "Rabbits don't need veterinary care.",
            "truth": "Like all pets, rabbits require regular veterinary check-ups, including vaccinations where necessary (like against Rabbit Hemorrhagic Disease in some countries) and spay/neuter surgeries to prevent health and behavioral issues."
        },
        {
            "myth": "Rabbits are happy living alone.",
            "truth": "Rabbits are social animals and often thrive in the company of another rabbit. However, introductions must be done carefully to ensure compatibility."
        },
        {
            "myth": "Rabits can be picked up by their ears.",
            "truth": "This is not only incorrect but also cruel. Rabbits should be supported under their hindquarters and chest when being picked up to prevent injury."
        },
        {
            "myth": "Rabbits don't need to be spayed or neutered.",
            "truth": "Spaying or neutering is vital for health reasons, including reducing the risk of cancer and helping prevent behavioral issues like territorial aggression and spraying."
        },
        {
            "myth": "It's okay to release domestic rabbits into the wild if you can't care for them.",
            "truth": "Domestic rabbits are not equipped to survive in the wild and will likely die if released. They also can become invasive species that threaten local ecosystems. Always rehome responsibly."
        },
        {
            "myth": "Rabbits are silent animals.",
            "truth": "While rabbits are not as vocally expressive as dogs or cats, they communicate through various sounds (like grunting, thumping, and even a soft purring noise when content) and body language."
        },
        {
            "myth": "Rabbits are dirty animals.",
            "truth": "While rabbits certainly produce a lot of poop, they mostly eat hay which means their poop is usually just condensed hay. Rabbits clean themselves meticulously like cats and are usually spotless."
        }
    ]
    return (
        <div>
            <Header/>
            <Stack justify="center" ml="md">
                <Text fw={700} size="100px">Common Myths About Rabbits</Text>
                <Stack w="80%" align="flex-start">
                    {commonMyths.map((item, index) => (
                        <Stack>
                            <Text fw={700}>Myth {index}: {item.myth}</Text>
                            <Text><strong>Truth:</strong> {item.truth}</Text>
                        </Stack>
                        ))}
                        <Text>Check out these <a href="/other-resources">Resources</a> to learn more about Rabbits.</Text>
                </Stack>
            </Stack>
            <Footer/>
        </div>
    );
};

export default CommonMythsPage;

