import React from "react";
import {Link} from "react-router-dom";
import "../../styles/NavigationBar.css";
import ArticleDropDown from "./ArticleDropDown";

function NavigationBar() {
    return (
      <nav className="nav-container">
        <ul className="nav-list">
          <li className="nav-item"><Link to="/" className="nav-link">Home</Link></li>
          <li className="nav-item"><Link to="https://chat.openai.com/g/g-nJiiwNx8N-rabbit-care" target="_blank" className="nav-link">Ask An AI Rabbit Expert</Link></li>
          <li className="nav-item"><Link to="/breeds" className="nav-link">Breeds</Link></li>
          <li className="nav-item"><Link to="/articles" className="nav-link"><ArticleDropDown/></Link></li>
          <li className="nav-item"><Link to="/underconstruction" className="nav-link">Social Media</Link></li>
        </ul>
      </nav>
    );
}

export default NavigationBar;