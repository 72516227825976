import React from "react";
import AuthHeader from "../components/header/AuthHeader";
import SignInForm from "../components/auth/SignInForm";
import "../styles/Page.css";

function SignInPage() {
    return (
        <div className="page-container">
            <div className="header-container">
                <AuthHeader/>
            </div>
            <div className="page-content">
                <h2>Sign In</h2>
                <SignInForm/>
            </div>
        </div>
    );
}

export default SignInPage;