import React from "react";
import {Link} from "react-router-dom";
import {Group, Container} from "@mantine/core"
import NavigationBar from "../navbar/NavigationBar";
import "../../styles/Header.css";
import SearchBar from "../search/SearchBar";
import AuthMenu from "../auth/AuthMenu";

function Header () {

    return (
        <header className="header">
            <Container fluid>
                <section className="header-title">
                    <h1 href="/" className="header-logo">
                        <Link to="/" className="header-logo-title">Rabbit Care</Link>
                    </h1>
                    {/* <SearchBar/> */}
                </section>
                <Group className="header-navbar">
                    <NavigationBar/>
                    {/* <AuthMenu/> */}
                </Group>
                {/* <div className="sign-in">
                    
                </div> */}
            </Container>
        </header>
    );       
}

export default Header;