import React from "react";
import Header from "../components/header/Header";
import Footer from "../components/footer/Footer";
import ImageCarousel from "../components/homepage/ImageCarousel";
import HomePageCarousel from "../components/homepage/HomePageCarousel";
import ResponsiveCards from "../components/homepage/ResponsiveCards";
import AboutUs from "../components/homepage/AboutUs";
import ArticleList from "../components/homepage/ArticleList";

import "../styles/HomePage.css";
import "../styles/Page.css";


function HomePage() {
    // Carousel with Welcome, Breed Explorer, Getting Started, Fun Things to Do
    // Mission Statement
    return (
        <div className="page-container">
            <div className="header-container">
                <Header/>
            </div>
            <div className="page-content">
                <p className="page-text">
                    Remember to try our <a href="https://chat.openai.com/g/g-nJiiwNx8N-rabbit-care" target="_blank" rel="noopener noreferrer">Rabbit Care AI!</a>
                </p>
                <div className="page-item" style={{background: "gray"}}>
                    {/* <ImageCarousel /> */}
                    <HomePageCarousel />
                </div>

                {/* <div className="page-item">
                    <ResponsiveCards />
                </div> */}
                <div id="about">
                    <AboutUs/>
                    {/* <ArticleList/> */}
                </div>
            </div>
            <Footer/>
        </div>
    );
}

export default HomePage;