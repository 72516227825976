import React from "react";
import AuthHeader from "../components/header/AuthHeader";
import SignUpForm from "../components/auth/SignUpForm";
import "../styles/Page.css";

function SignUpPage() {
    return (
        <div className="page-container">
            <div className="header-container">
                <AuthHeader/>
            </div>
            <div className="page-content">
                <h2>Sign Up</h2>
                <SignUpForm/>
            </div>
        </div>
    );
}

export default SignUpPage;